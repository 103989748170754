<template>
  <div class="my-package pt-3 pt-md-0">
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <v-row class="ma-1 mt-16 ma-md-3">
      <v-col cols="12" class="filter-deal">
        <v-autocomplete
          v-model="payload.branch"
          :items="listBranch"
          item-value="id"
          item-text="name"
          class="input-branch"
          :label="$t('branch')"
          rounded
          dense
          hide-details
          prepend-icon="mdi-home-modern"
          append-icon=""
          ref="autoBranch"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-row v-for="(deal, index) of listDeal" :key="index">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6">
                <div class="type-deal">{{ deal.name }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-for="(product, index) of deal.available_packages"
            :key="index"
          >
            <card-deal
              :action="
                payload.branch != '' &&
                payload.branch != null &&
                payload.branch != undefined
              "
              :title="product.name"
              :data="deal"
              :paket="product.id"
              :disc="`IDR ${formatPrice(product.amount_after_deal)}`"
              :sub_title="`IDR ${formatPrice(product.amount_before_deal)}`"
              :desc="`${product.products.length} ${$t('test_covered')}`"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import CardDeal from "@/components/v3/cards/card-deal.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";

export default {
  components: {
    CardDeal,
    NavbarOne,
  },
  data: () => ({
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    listDeal: null,
    listBranch: [],
    payload: {
      branch: "",
    },
  }),
  watch: {
    "payload.branch": function () {
      history.replaceState(
        null,
        null,
        `?plan=${this.payload.branch == null ? "" : this.payload.branch}`
      );
      this.getListDeal();
    },
  },
  created() {
    this.getListDeal();
    this.getBranch();
  },
  methods: {
    async getListDeal() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}deal/get?plant_id=${this.payload.branch}&lang=${this.$i18n.locale}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          this.listDeal = resp.results;
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
          });
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },
    async getBranch() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.listBranch = resp.results;
        if (
          this.$route.query.plan != "" &&
          this.$route.query.plan != undefined
        ) {
          this.payload.branch = parseInt(this.$route.query.plan);
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
  },
};
</script>

<style lang="scss">
.filter-deal {
  .input-branch {
    background: white;
    margin-top: 0;
    align-items: center;
  }
  .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0;
    input {
      font-size: 14px;
    }
  }
  .v-input__prepend-outer .v-icon {
    margin-left: 14px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.type-deal {
  color: white;
  font-size: 14px;
  text-transform: capitalize !important;
  background: $dark_blue_2;
  border-radius: 0 16px 16px 0;
  padding: 4px 12px;
  min-width: 50%;
  width: max-content;
}
</style>
